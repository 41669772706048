.navigation {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  align-self: center;
  transition: all 0.5s ease-out;
  &_isOpen {
    top: 0 !important;
  }
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__item {
    box-sizing: border-box;
    height: 2.5rem;
    padding: .5rem 0.3rem;
    background: #1d222a;
    border-right: 1px solid #ff8d01;
    border-left: 1px solid #ff8d01;
    color: #FFF;
    transition: all 0.5s ease-out;
    &_active {
      color: #ff8d01;
    }
  }
  &__button {
    background: #FF8D00;
    padding: .2rem 1rem;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  &__arrow {
    line-height: 0;
    display: block;
    transform: rotate(90deg);
    width: 1rem;
    height: 1rem;
    transition: all .5s ease-out;
    &_isOpen {
      transform: rotate(-90deg);
    }
    * {
      fill: #FFF;
    }
  }
}

@media screen and (min-width: 700px) {
  .navigation {
    &__item {
      padding: .5rem 1.5rem;
    }
    &__button {
      min-height: 2rem;
      max-height: 2rem;
    }
  }
}