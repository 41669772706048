.confirmation {
  background: #2A3040;
  border: 1px solid #808080;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  &__title {
    font-size: 2rem;
    color: #FF8D00;
    text-decoration: underline;
    text-align: center;
  }
  &__text {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  &__buttons {
    display: flex;
    margin-top: 1rem;
    width: 100%;
  }
  &__button-container {
    height: 2.5rem;
    flex-grow: 1;
    &:first-child {
      margin-right: 1rem;
    }
  }
}