.table {
  width: 100%;
  box-sizing: border-box;
  margin-top: 1.5rem;
  border: 1px solid #808080;
  font-size: 0.85rem;
  position: relative;
  &_isBig {
    width: 200%;
  }
  &__button {
    position: absolute;
    width: 2.5rem;
    height: 1.5rem;
    background: #FF8D00;
    top: -1.5rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 700px) {
  .table {
    width: 100%;
    &_isBig {
      width: 100%;
    }
    &__button {
      display: none;
    }
  }
}