.create {
  background: #2A3040;
  border: 1px solid #808080;
  width: 90%;
  max-width: 40rem;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  &__wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: #FF8D00;
    text-decoration: underline;
    text-align: center;
  }
  &__item {
    margin-top: .5rem;
  }
  &__name {
    margin-bottom: .2rem;
  }
  &__button-container {
    height: 2.5rem;
    margin-top: 1rem;
  }
  &__add-button {
    width: 3rem;
    height: 3rem;
    background: #FF8D00;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    position: fixed;
    left: 0.5rem;
    bottom: 0.5rem;
    cursor: pointer;
  }
}