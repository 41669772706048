.admin {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  &__content {
    min-height: calc(100% - 4rem);
    position: relative;
    display: flex;
    overflow-y: scroll;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) {
  .admin {
    &__content {
      min-height: calc(100vh - 5.7rem);
      max-height: calc(100vh - 5.7rem);
    }
  }
}