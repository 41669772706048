.modal {
  width: 90%;
  max-width: 40rem;
  max-height: 80vh;
  overflow-y: auto;
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}