.global-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  animation: filtersStart .5s ease-in-out forwards;
  z-index: 10;
  box-sizing: border-box;
  border-bottom: 1px solid #3A4768;
  margin-bottom: 2rem;

  &__item {
    width: 50%;
    padding: 0.5rem;
    box-sizing: border-box;
    &_isSubmitButton {
      padding-top: 1.8rem;
    }
    &_isButton {
      width: 25%;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.3rem;
  }
}

@media screen and (min-width: 700px) {
  .global-buttons {
    &__item {
      width: 20%;
      padding: .7rem 1%;
      &_isSubmitButton {
        padding-top: 2.2rem;
      }
      &_isButton {
        width: 10%;
        padding-top: 2.2rem;
      }
    }
  }
}