.header {
  background: #2A3040;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  color: #fff;
  white-space: nowrap;
  transform: translateY(-100%);
  animation: headerStart .5s ease-in-out 1s forwards;

  &__exit {
    width: 2.5rem;
    height: 2.5rem;
    background: #13171D;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    &-svg {
      * {
        fill: #FF8D00;
      }
      width: 1.5rem;
      display: block;
      line-height: 0;
    }
  }
  &__info {
    margin-left: auto;
    text-align: right;
    height: 2.5rem;
  }
  &__name {
    margin-bottom: 0.3rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__role {
    font-size: 0.7rem;
  }
  &__logo {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  &__text {
    color: #FF8D00;
    font-size: 0.9rem;
    font-weight: 400;
    margin-right: 0.4rem;
    line-height: 0.85rem;
    text-align: right;
  }
}

@media screen and (min-width: 700px) {
  .header {
    height: 5.7rem;
    &__exit {
      width: 4rem;
      height: 4rem;
      &-svg {
        width: 2.2rem;
      }
    }
    &__info {
      height: 4rem;
    }
    &__name {
      font-size: 1.4rem;
    }
    &__role {
      font-size: 0.8rem;
    }
  }
}

@keyframes headerStart {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  } 
}