.button {
  color: #fff;
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  outline: none;

  &_isDisabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  &_isUppercase {
    text-transform: uppercase;
  }

  &_isBold {
    font-weight: bold;
  }

  &_isSquare {
    border-radius: 0;
  }

  &_fontSize {
    &_small {
      font-size: 0.85rem;
    }
    &_normal {
      font-size: 1rem;
    }
    &_large {
      font-size: 1.2rem;
    }
  }
  &_color {
    &_orange {
      background: #FF8D00;
      border: 1px solid #FF8D00;
    }
    &_dark {
      background: #2A3040;
      border: 1px solid #FF8D00;
    }
    &_dark-fill {
      background: #3A4768;
      border-radius: 0;
    }
  }
  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
  }
  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;
    &_isDisabled {
      cursor: not-allowed;
    }
    &_fontSize {
      &_small {
        font-size: 1rem;
      }
      &_normal {
        font-size: 1.1rem;
      }
      &_large {
        font-size: 1.3rem;
      }
    }
  }
}