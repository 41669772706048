.auth {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 20px 5px #2A3040;
  
  &__back {
    width: 100%;
  }
  &__title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
  &__container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 20%;
  }
  &__input-block {
    width: 100%;
    margin-bottom: 2.8rem;
  }
  &__label {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    margin-top: 1.5rem;
  }
}