.specific-button {
  width: 2rem;
  height: 2rem;
  background: #FF8D00;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  left: 0.5rem;
  bottom: 0.5rem;
  cursor: pointer;
}