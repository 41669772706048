.table-row {
  width: 100%;
  height: 2.5rem;
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #808080;
  position: relative;
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.5rem;
    border-right: 1px solid #808080;
    user-select: all !important;
    overflow: overlay;
  }
  &__edit {
    width: 2rem;
    margin-left: .4rem;
    * {
      fill: #FF8D00;
    }
  }
  &__buttons-block {
    position: absolute;
    right: .2rem;
    display: flex;
    align-items: center;
  }
}