.locale {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  transition: all .5s ease-in-out;
  box-sizing: border-box;
  background: #FF8D00;
  padding: 0.3rem;
  font-size: 1.1rem;

  &_isOpen {
    background: #2A3040;
    border: 1px solid #FFFFFF;
    padding: 1rem;
  }

  &__item {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }
    &_active {
      color: #FF8D00;
    }
  }
}

@media screen and (min-width: 700px) {
  .locale {
    position: fixed;
    right: 0.5rem;
    bottom: 0.5rem;
    transition: all .5s ease-in-out;
    box-sizing: border-box;
    background: #FF8D00;
    padding: 0.5rem;
    font-size: 1.2rem;
  
    &_isOpen {
      background: #2A3040;
      border: 1px solid #FFFFFF;
      padding: 1.5rem;
    }
  }
}