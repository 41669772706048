.app {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background: #1D212A;
  display: flex;
  // align-items: center;
  justify-content: center;
  color: #FFF;
  &__header-layout {
    width: 100%;
    height: 4.2rem;
  }
}

#preloader {
  &::after {
    content: 'fiablegames.com';
    color: #FFF;
    position: absolute;
    padding-top: 4rem;
    text-transform: uppercase;
    font-size: .9rem;
  }
}

@media screen and (min-width: 700px) {
  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700,900");

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #000;
}

html {
  font-size: 14px;
  @media screen and (min-width: 700px) {
    font-size: 14px;
  }
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
button,
div,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
  user-select: none;
  &::-webkit-scrollbar {
    margin-top: 3px;
    width: 2px;
    height: 0;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #FF8D00;
    border-radius: 50px;
  }
}

@media screen and (orientation:landscape) and (max-device-width: 900px) {
  html{
      -webkit-transform: rotate(-90deg);
         -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
           -o-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: left top;
         -moz-transform-origin: left top;
          -ms-transform-origin: left top;
           -o-transform-origin: left top;
              transform-origin: left top;
      width: 320px; /*Разрешение экрана iPhone*/
      position: absolute;
      top: 100%;
          left: 0
  }
}