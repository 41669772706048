.table-header {
  width: 100%;
  height: 2.5rem;
  display: flex;
  box-sizing: border-box;
  font-weight: 600;
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.5rem;
    border-right: 1px solid #808080;
    &_isSortable {
      // background: rgba(255, 255, 255, .05);
      cursor: pointer;
    }
  }
}