.additional-info {
  box-sizing: border-box;
  padding: 0.5rem;
  user-select: text;
  &__item {
    margin-right: 1rem;
  }
  &__currency-total {
    box-sizing: border-box;
    margin-top: .3rem;
    user-select: text;
  }
}